module controllers {
    export module master {
        interface IAddContainerScope extends ng.IScope {
        }
        interface IAddContainerParams extends ng.ui.IStateParamsService {
            contId: number;
        }

        export class addContainerController {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$anchorScroll', 'bsLoadingOverlayService', '$state', '$timeout', "$stateParams",
                "sundryItemService", "costModelElementService", "vatRateService",
                "classificationValueService", "unitOfMeasureService", "commercialInvoiceService",
                "warehouseService", "isInbound", "containerService", "consignmentService", "containerISOService"
            ];
            
            containerItem: interfaces.logistics.IContainer;
            
            ContainerStowageList: Array<interfaces.applicationcore.IDropdownModel>;

            breadCrumbDesc: string;

            constructor(private $scope: IAddContainerScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private $stateParams: IAddContainerParams,
                private sundryItemService: interfaces.master.ISundryItemService,
                private costModelElementService: interfaces.costing.ICostModelElementService,
                private vatRateService: interfaces.master.IVatRateService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                private warehouseService: interfaces.master.IWarehouseService,
                private isInbound: boolean,
                private containerService: interfaces.logistics.IContainerService,
                private consignmentService: interfaces.consignment.IConsignmentService,
                private containerISOService: interfaces.applicationcore.IContainerISOService,
            ){
                this.loadContainer();
            }

            loadContainer() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.cont'
                }, () => {
                    //Loads Container
                    return this.containerService.getContainer(this.$state.params['conId'], this.$state.params['manId'], this.$state.params['contId']).get((result: interfaces.logistics.IContainer) => {
                        this.containerItem = result;

                        this.breadCrumbDesc = this.containerItem.Number;
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });                
            }

            SignOff()
            {
                this.generalService.displayConfirmationBox("Confirm Sign Off?", `Are you sure you want to Sign Off the Container?`).then((result) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.cont'
                        }, () => {
                            return this.containerService.signOff().save({}, this.containerItem, (result) => {
                                this.generalService.displayMessageHandler(result);
                                if (!result.HasErrorMessage) {

                                    this.$timeout(() => {
                                        this.loadContainer();
                                    });

                                    this.$anchorScroll("topAnchor");
                                }

                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                }).$promise;
                        });
                    }
                });
            }

            loadSOLASMethod() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.SOLASMethod
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            SOLASChanged(model: interfaces.applicationcore.IDropdownModel)
            {
                if (model)
                {
                    //If Method 2
                    if (model.Id == 657) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.cont'
                        }, () => {
                            if (this.containerItem)
                            {
                                if (this.containerItem.ManifestId)
                                {
                                    return this.containerService.calculateDunnageWeight(this.containerItem.Id, this.containerItem.ManifestId, this.containerItem.ConsignmentId).get((result) => {
                                        this.containerItem.DunnageWeight = result.value ? result.value : this.containerItem.DunnageWeight;
                                        this.sumWeights(model.Id);
                                    }, (failureData) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                    }).$promise;
                                }
                            }
                        });
                    }

                    if (model.Id == 656) {
                        this.sumWeights(model.Id);
                    }
                }
            }

            //Does the sum of the 3 weights for gross mass.
            sumWeights(Id: number)
            {
                if (Id == 657) {
                    var netWeight = this.containerItem.NetWeight ? this.containerItem.NetWeight : 0;
                    var dunnageWeight = this.containerItem.DunnageWeight ? this.containerItem.DunnageWeight : 0;
                    var tareWeight = this.containerItem.TareWeight ? this.containerItem.TareWeight : 0;

                    this.containerItem.GrossMass = Number(netWeight) + Number(dunnageWeight) + Number(tareWeight);
                }

                if (Id == 656) {
                    this.containerItem.NetWeight = null;
                    this.containerItem.DunnageWeight = null;
                    this.containerItem.TareWeight = null;
                }
            }

            loadStatus() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ContainerStatus
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }
            
            loadSize(searchText: string) {
                return this.containerISOService.getContainerISODropdown(searchText).query(
                    () => {

                    }, (failureData) => {
                    }).$promise;
            }

            loadType() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ContainerType
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSealingParty1() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ContainerSealingParty
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSealingParty2() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ContainerSealingParty
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSealType1() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.SealType
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSealType2() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.SealType
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadContainerStowage() {
                return this.containerService.getContainerStowage().query((result) => {
                    this.ContainerStowageList = result;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSealingPoint1() {
                return this.warehouseService.getDropdownList().query({
                    ownerEntityId: this.containerItem.OwnerEntityId,
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadSealingPoint2() {
                return this.warehouseService.getDropdownList().query({
                    ownerEntityId: this.containerItem.OwnerEntityId,
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }
            
            Save(close: boolean) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.cont'
                }, () => {
                    
                    return this.containerService.save().save({}, this.containerItem, (result) => {

                        this.generalService.displayMessageHandler(result);
                        this.$anchorScroll("topAnchor");

                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                if (this.containerItem.Id) {
                                    this.loadContainer();
                                } else {
                                    this.$state.params["contId"] = Number(result.ID);
                                    this.$state.go(this.$state.current.name, this.$state.params);
                                }

                                if (close)
                                {
                                    this.Close();
                                }
                            });

                        }
                    }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                });
            }
            
            Close() {
                this.containerItem = undefined;
                this.$anchorScroll("topAnchor");
                this.$state.go("^");
            }
        }

        angular.module("app").controller("addContainerController", controllers.master.addContainerController);
    }
}